<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 14:33:54
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-09 14:21:53
-->
<template>
  <div class="balance-wallet">
    <div class="personal-center-title">
      保证金余额
    </div>
    <div class="balance-wallet-body">
      <div class="balance-wallet-total">
        <div class="grid-content number-red">
          <p class="number">{{ $utils.formatMoney(userInfo.margin) }}</p>
          <p class="text">保证金余额</p>
        </div>
        <div class="balance-wallet-btn">
          <!-- <i class="iconfont icon-guize"></i> -->
          <!-- <span style="margin-left: 8px;" @click="rulesDetails">规则</span> -->
          <!-- <el-button type="danger" @click="handleWithdrawal">提现</el-button> -->
        </div>
      </div>
      <div class="balance-wallet-detailed">
        <el-radio-group v-model="tabPosition" @change="gettWalletInfo">
          <el-radio-button label=" ">全部</el-radio-button>
          <el-radio-button label="1">充值</el-radio-button>
          <el-radio-button label="2">罚款</el-radio-button>
        </el-radio-group>
        <el-table stripe :data="tableData" style="width: 100%">
          <el-table-column prop="createDate" label="交易时间">
          </el-table-column>
          <el-table-column prop="number" label="订单号"> </el-table-column>
          <el-table-column prop="remarke" label="收支类型"> </el-table-column>
          <el-table-column prop="money" label="收支金额" width="150px">
            <!-- <template slot-scope="scope" >
              <span v-show="scope.row.state == 1">+￥{{ $utils.formatMoney(scope.row.money) }}</span>
              <span v-show="scope.row.state == 2">-￥{{ $utils.formatMoney(scope.row.money) }}</span>
            </template> -->
          </el-table-column>
        </el-table>
        <u-pagination v-if="total > 0" :total="total" @change="changePagination"></u-pagination>
      </div>
    </div>
    <!-- <operation ref="operation"></operation> -->
  </div>
</template>

<script>
// import operation from "./operation";
import { session } from "@/utils/sessions";
import UPagination from "@/components/UPagination";

export default {
  components: {
    // operation,
    UPagination
  },
  data() {
    return {
      userInfo: {},
      tableData: [],
      tabPosition: " ",
      total: 0,
      tableParams: {
        num: 10,
        page: 1
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },
  mounted() {
    this.getUserInfo();
    this.customerMargin();
  },
  methods: {
    // handleWithdrawal() {
    //   this.$refs.operation.init();
    // },
    changePagination(page, pageSize) {
      this.tableParams = {
        num: pageSize,
        page: page
      };
      this.customerMargin();
    },

    gettWalletInfo() {
      this.tableParams.page = 0;
      this.customerMargin();
    },

    getUserInfo() {
      this.$api.getUserInfo({ id: this.userId }).then(user => {
        this.userInfo = user.data;
        session.setUsers(user.data);
        this.$store.dispatch("getUser");
      });
    },
    customerMargin() {
      let params = {
        id: this.userId,
        num: this.tableParams.num,
        page: this.tableParams.page,
        type:this.tabPosition
      };
      const loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .customerMargin(params)
        .then(res => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    rulesDetails() {
      window.open(`/#/wallet/details?type=4`);
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
